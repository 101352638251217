import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styledComponents';

const P = styled.p`
  color: ${colors.navy};
  ${props => props.center ? 'text-align: center;' : ''}
  font-size: 48px;
  margin: ${props => props.margin};
`;

const Dots = ({ center, margin }) => (
  <P center={center} margin={margin}>⋅ ⋅ ⋅</P>
);

Dots.propTypes = {
  center: PropTypes.bool,
  margin: PropTypes.string,
};

Dots.defaultProps = {
  center: false,
  margin: '0px',
};

export default Dots;
