import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { margins, fontSizes, mobileThresholdPixels, Column, YellowLineH } from './v2/StyledComponents';

const Container = styled.div`
  margin: 10px auto;
  flex: 1;
  text-align: center;
  max-width: 300px;
`;

const Number = styled.p`
  margin: ${margins.m} 0px 0px;
  font-family: Gotham;
  font-size: ${fontSizes.x2l};
`;

const Body = styled.div`
  margin-top: 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
  }
`;

const StyledTitle3 = styled.h3`
  font-family: Roboto;
  font-weight: 500;
  font-size: ${fontSizes.xl};
  text-transform: none;
  margin: 0px 0px ${margins.l} 0px;
`;

const NumberedBlock = ({ children, number, title }) => (
  <Container>
    {number &&
      <Column alignItems={'center'}>
        <Number>{number}</Number>
        <YellowLineH />
      </Column>
    }
    {title && <StyledTitle3>{title}</StyledTitle3>}
    <Body>{children}</Body>
  </Container>
);

NumberedBlock.propTypes = {
  children: PropTypes.node.isRequired,
  number: PropTypes.number,
  title: PropTypes.string,
};

NumberedBlock.defaultProps = {
  icon: '',
  number: 0,
  title: '',
  linkTo: '',
};

export default NumberedBlock;
