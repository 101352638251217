import React from 'react';
import { Container, Content } from '../../components/home/homeStyledComponents';
import MenuBar from '../../components/home/MenuBar';
import About from '../../components/home/About';
import SocialNetworks from '../../components/home/SocialNetworks';
import Footer from '../../components/home/Footer';
import Layout from '../../layouts/index';

class AboutPage extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Layout routeSlug="AboutPage">
        <Container>
          <Content>
            <MenuBar />
            <About />
            <SocialNetworks />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default AboutPage;
